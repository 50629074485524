
import { Component, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { PageMode } from '@/utils/type'
import { apiPictureAdd, apiPictureEdit, apiIpLists } from '@/api/shop'
// import { UserLevelDetail_Req, UserLevelDetail_Res, UserLevelAdd_Req, UserLevelEdit_Req } from '@/api/user/user.d'
@Component({
  components: {
    MaterialSelect
  }
})
export default class BrandImageEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD // 当前页面【add: 添加用户等级 | edit: 编辑用户等级】
    identity: number | null = null // 当前编辑用户的身份ID  valid: mode = 'edit'
    isLevel: number | null = null // 用户等级

    disabled: any = false // 是否禁用 查看详情时禁用
    form = {
      title: '',
      image: '',
      is_show: 1,
      price: '',
      brand_id: ''
    }

    formRules = {
      title: [
        {
          required: true,
          message: '请输入图片名称',
          trigger: 'blur'
        }
      ],
      price: [
        {
          required: true,
          message: '请输入图片价格',
          trigger: 'blur'
        }
      ]
    }

    authorList = []
    $refs!: {
        formRef: any
    }
    /** E Data **/

    /** S Methods **/
    // 表单提交
    onSubmit () {
      // 验证表单格式是否正确
      this.$refs.formRef.validate((valid: boolean): any => {
        if (!valid) {
          return
        }

        // 请求发送
        switch (this.mode) {
          case PageMode.ADD:
            return this.handleUserLevelAdd()
          case PageMode.EDIT:
            return this.handleUserLevelEdit()
        }
      })
    }

    // 获取品牌
    getAuthorList () {
      apiIpLists({}).then(res => {
        this.authorList = res.lists
      })
    }

    // 新增用户等级
    handleUserLevelAdd () {
      // @ts-ignore
      const form = this.form
      apiPictureAdd(form)
        .then(() => {
          // this.$message.success('添加成功!')
          setTimeout(() => this.$router.go(-1), 500)
        })
        .catch(() => {
          // this.$message.error('保存失败!')
        })
    }

    // 编辑用户等级
    handleUserLevelEdit () {
      const params = this.form
      const id: number = this.identity as number
      // @ts-ignore
      apiPictureEdit({ ...params, id })
        .then(() => {
          // this.$message.success('修改成功!')
          setTimeout(() => this.$router.go(-1), 500)
          // this.initFormDataForUserLevelEdit()
        })
        .catch(() => {
          // this.$message.error('保存失败!')
        })
    }

    // 表单初始化数据 [编辑模式] mode => edit
    initFormDataForUserLevelEdit (info: any) {
      // Object.keys(info).map(key => {
      //     this.$set(this.form, key, info[key])
      // })
      this.form.title = info.title
      this.form.image = info.image
      this.form.price = info.price
      this.form.brand_id = info.brand_id
      this.identity = info.id
    }

    /** E Methods **/

    /** S Life Cycle **/
    created () {
      const query: any = this.$route.query

      if (query.mode) {
        this.mode = query.mode
      }
      this.disabled = this.$route.query.disabled != null
      this.getAuthorList()
      // 编辑模式：初始化数据
      if (this.mode === PageMode.EDIT) {
        this.initFormDataForUserLevelEdit(query.info)
      }
    }
    /** E Life Cycle **/
    // 监听等级条件是否有输入，输入即勾选
}
